
export default {
    name: 'PopAd',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            clear: '',
            pop: {
                visible: false,
                hasIcon: false,
                hasPage: true,
                list: []
            },
            params: {
                hasPopup: false, // 是否弹过
                hasFirstLogin: true, // 是否每日首次登录
                loginType: 1, // 1 未登录 2 已登录
                page: '', // 位置
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        if(!process.client) return
        this.getPopupStatus()
        this.getPageType()
        if(!this.params.hasPopup && this.pop.hasPage) {
            this.setPopupStorage()
            this.getLogin()
            this.getPosition()
            // this.getPopAdAjax()
        }
    },
    methods: {
        getPopAdAjax() {
            this.$api.mixins.getPopAd({
                login: this.params.hasFirstLogin,
                popupType: this.params.loginType,
                page: this.params.page,
            }).then(response => {
                const res = response.result || [];
                this.pop.list = res
                this.pop.visible = !!this.pop.list.length
                this.setData()
            })
        },
        setData() {
            this.pop.list.forEach(p => {
                const first = p.firstAppCover[0] || {};
                p.rsUrl = first.url
            })
            this.clear = setTimeout(() => {
                this.pop.hasIcon = true
                clearTimeout(this.clear)
            }, 1500)
        },
        // 获取登录类型
        getLogin() {
            this.params.hasFirstLogin = true
            this.params.loginType = this.$login() ? 2 : 1
        },
        // 获取当前页位置
        getPosition() {
            const pathname = this.$route.name || {};
            this.params.page = 'otherPage';
            switch(pathname) {
                case 'Home':
                    this.params.page = 'homePage';
                break;
                case 'Product-Seo':
                    this.params.page = 'goodsPage';
                break;
                case 'CategoryDetail-Seo':
                    this.params.page = 'categoryPage';
                break;
            }
            if(pathname.includes('Activity')) {
                this.params.page = 'activityPage';
            }
        },
        // 获取弹窗状态
        getPopupStatus() {
            this.params = this.$storage.get('biz/inPopup') || {}
            // 最后时间戳
            let lastStamp = new Date(new Date().setHours(0, 0, 0, 0) + 24 * 60 * 60 * 1000 - 1).getTime()
            // 零点时间戳
            let zeroStamp = new Date(new Date().setHours(0, 0, 0, 0)).getTime()
            // 当前时间戳
            let nowStamp = new Date().getTime();
            if(nowStamp > this.params.lastStamp) {
                this.params = {}
            }
            this.params.nowStamp = nowStamp
            if(lastStamp != this.params.lastStamp) {
                this.params.lastStamp = lastStamp
            }
            if(zeroStamp != this.params.zeroStamp) {
                this.params.zeroStamp = zeroStamp
            }
            // 登录/注册后弹窗逻辑
            this.params.hasLogin = this.$login()
            this.params.hasRegister = this.$storage.get('biz/hasInPopupRegister') || false
            if(this.params.hasLogin) {
                this.params.hasPopup = true
            }
            if(this.params.hasRegister) {
                this.params.hasPopup = false
                this.$storage.remove('biz/hasInPopupRegister')
            }
        },
        // 获取页面类型
        getPageType() {
            const pageArr = [
                '/address',
                '/orderconfirm',
                '/cardpay',
                '/paysuccess',
                '-pwd',
                'market',
                'coupon',
                'login',
                'review-'];
            const pathname = location.href;
            pageArr.some(p => {
                if(pathname.includes(p)) {
                    this.pop.hasPage = false
                    return true
                }
            })
        },
        // 弹窗数据存储
        setPopupStorage() {
            this.params.hasPopup = true
            this.$storage.set('biz/inPopup', this.params)
        },
        // 关闭
        handleClose() {
            this.pop.visible = false
        },
        // 广告
        handleAd(obj) {
            switch(obj.popupEvent) {
                case 'jump_registered':
                    this.$router.push({
                        name: 'Login'
                    })
                break;
                case 'jump_coupon_list':
                    if(this.params.loginType == 1) {
                        this.$router.push({
                            name: 'Login'
                        })
                    } else {
                        this.$router.push({
                            name: 'Coupon'
                        })
                    }
                break;
                case 'close_popup':
                    this.pop.visible = false
                break;
                default:
            }
        },
        getContainer() {
            return document.querySelector('.popAd')
        }
    },
}
