
export default {
    name: 'SEO',
    components: {},
    async asyncData({$api, ...context}) {
        const [response] = await Promise.all([
            $api.common.getCountryList()
        ])
        // 商品详情
        const res = response.result || [];
        return {
            queryInfo: res,
        }
    },
    props: {},
    data() {
        return {
            lang: {},
            meta: {
                otherMeta: [],
                merge: []
            },
            link: [],
            htmlAttrs: {},
            headAttrs: {},
            mRealmName:'',
            queryInfo:[
                {mRealmName:"https://www.yfn.com",showName:"United States"},
                {mRealmName:"https://au.yfn.com",showName:"Australia"},
                {mRealmName:"https://br.yfn.com",showName:"Brazil"},
                {mRealmName:"https://ca.yfn.com",showName:"Canada"},
                {mRealmName:"https://www.yfn.fr",showName:"France"},
                {mRealmName:"https://m.yfn.de",showName:"Germany"},
                {mRealmName:"https://it.yfn.com",showName:"Italy"},
                {mRealmName:"https://m.yfn.mx",showName:"Mexico"},
                {mRealmName:"https://nl.yfn.com",showName:"Netherlands"},
                {mRealmName:"https://www.yfn.pl",showName:"Poland"},
                {mRealmName:"https://pt.yfn.com",showName:"Portugal"},
                {mRealmName:"https://www.yfn.es",showName:"Spain"},
                {mRealmName:"https://m.yfn.uk",showName:"United Kingdom"}
            ],
            footlist:[{
                text: this.$translate('About Us'),
                url: '/about-us',
            }, {
                text: this.$translate('Create Your Own Jewelry'),
                url: '/policy?id=1679650022685av6Gn0JX'
            }, {
                text: this.$translate('Create Your Own Gift Packaging'),
                url: '/policy?id=1679650036003LnGpZ4fw'
            }, {
                text: this.$translate('Sustainability'),
                url: '/Sustainability'
            }, {
                text: this.$translate('YFN Exchange'),
                url: '/jewelry-exchange'
            }, {
                text: this.$translate('YFN Reviews'),
                url: '/reviews'
            }, {
                text: this.$translate('Site Index'),
                url: '/sindex'
            }, {
                text: this.$translate('Alphabetical Tags Index'),
                url: '/pindex'
            }]
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        setHead() {
            this.mergeHead()
            return this.headAttrs
        },
        // 语言
        setLang() {
            const storeLang = this.$store.state.national.language ||  {};
            this.lang = this.$storage.get('language') || storeLang;
            // this.htmlAttrs.lang = this.lang.langCode || 'en'
        },
        // 阿语适配
        setDir() {
            const dir = ['ar_SA'].includes(this.lang.identify) ? 'rtl' : 'ltr';
            this.htmlAttrs.dir = dir
        },
        // meta
        setOtherMeta() {
            // Safari分享图标和标题
            this.meta.otherMeta = [{
                key: 'name',
                keyVal: 'apple-mobile-web-app-capable',
                content: 'yes'
            }, {
                key: 'name',
                keyVal: 'apple-touch-fullscreen',
                content: 'yes'
            }, {
                key: 'name',
                keyVal: 'apple-mobile-web-app-title',
                content: 'YFN'
            }]
            // robots
            // if(this.$route.name != 'Home') {
            //     this.meta.otherMeta.push({
            //         key: 'name',
            //         keyVal: 'robots',
            //         content: 'noindex'
            //     })
            // }
            // 网站归属验证
            const countryStore = this.$store.state.national.country
            const country = this.$storage.get('country') || countryStore || {};
            const mRealmName = country.mRealmName || '';
            let verification = {
                key: 'name',
                keyVal: 'google-site-verification',
            };
            let site_name = {
                key: 'property',
                keyVal: 'og:site_name',
                content: 'YFN'
            }
            this.mRealmName = mRealmName
            if(mRealmName.includes('yfn.uk')) {
                verification.content = 'hvc3zvN4dMIyJToxzvZ84xPq8jw6v_Ruuh3H7xmi34Q'
                site_name.content = 'YFN UK'
            }
            if(mRealmName.includes('yfn.fr')) {
                verification.content = '-TH88S38USQl-Rbwc25OvzQOXMCUVsAAG5RPpG8DnwY'
                site_name.content = 'YFN FR'
            }
            if(mRealmName.includes('yfn.es')) {
                verification.content = '0Me8rJMK2pvtmKRwRFHyCwdOIhK2P2sXbiqCRk2qY8Y'
                site_name.content = 'YFN ES'
            }
            if(mRealmName.includes('yfn.pl')) {
                verification.content = 'C7riwvBX-owJvUpuFisd99IjbebERakEDvd78LAgdUc'
                site_name.content = 'YFN PL'
            }
            if(mRealmName.includes('yfn.ae')) {
                verification.content = 'iCDDdjX3L4sI2h1Cv6yBRHsw0RYs99RSwGF3G3JasY8'
            }
            if(mRealmName.includes('yfn.mx')) {
                verification.content = 'McUgR1cu5dpfYCs0lmJsMajbY_UQpH13iYEDDO0a4J8'
                site_name.content = 'YFN MX'
            }
            if(mRealmName.includes('yfn.jp')) {
                verification.content = 'LOVRKJMk8gIC7D9H57gcjPlGysxeyFXX-jduiZiEWI4'
            }
            if(mRealmName.includes('yfn.de')) {
                verification.content = 'Fz2A8G-yagOxDhqnuDR2lebYsAvU6mHMEgb0kDt-fcA'
                site_name.content = 'YFN DE'
            }
            if(mRealmName.includes('ca.yfn.com')) {
                site_name.content = 'YFN CA'
            }
            if(mRealmName.includes('au.yfn.com')) {
                site_name.content = 'YFN AU'
            }
            if(mRealmName.includes('br.yfn.com')) {
                site_name.content = 'YFN BR'
            }
            if(mRealmName.includes('it.yfn.com')) {
                site_name.content = 'YFN IT'
            }
            if(mRealmName.includes('nl.yfn.com')) {
                site_name.content = 'YFN NL'
            }
            if(mRealmName.includes('pt.yfn.com')) {
                site_name.content = 'YFN PT'
            }
            if(!verification.content) {
                verification.content = '1eQ3PBY7n2VJbg4n3GhifQ0uz77tZO6aNIHNUtezx68'
            }
            this.meta.otherMeta.push(verification)
            this.meta.otherMeta.push(site_name)
        },
        setMergeMeta() {
            this.setOtherMeta()
            this.meta.merge = [];
            const metaArr = [...this.meta.otherMeta];
            metaArr.forEach(p => {
                const obj = {
                    hid: p.keyVal,
                    [p.key]: p.keyVal,
                    content: p.content
                };
                this.meta.merge.push(obj)
            })
        },
        // link
        setAltLink() {
            const fullPath = this.$route.path;
            let list = [
                {
                    hreflang: 'en-US',
                    href: 'https://m.yfn.com'
                }, 
                {
                    hreflang: 'pt-BR',
                    href: 'https://br.yfn.com'
                }, 
                {
                    hreflang: 'en-AU',
                    href: 'https://au.yfn.com'
                }, 
                {
                    hreflang: 'en-CA',
                    href: 'https://ca.yfn.com'
                }, 
                {
                    hreflang: 'fr',
                    href: 'https://www.yfn.fr'
                }, 
                {
                    hreflang: 'de',
                    href: 'https://m.yfn.de'
                }, 
                // {
                //     hreflang: 'ga-IE',
                //     href: 'https://ie.yfn.com'
                // }, 
                {
                    hreflang: 'it',
                    href: 'https://it.yfn.com'
                }, 
                {
                    hreflang: 'es',
                    href: 'https://www.yfn.es'
                }, 
                // {
                //     hreflang: 'en-JP',
                //     href: 'https://m.yfn.jp'
                // }, 
                {
                    hreflang: 'es-MX',
                    href: 'https://m.yfn.mx'
                }, 
                {
                    hreflang: 'en-GB',
                    href: 'https://m.yfn.uk'
                }, 
                {
                    hreflang: 'nl',
                    href: 'https://nl.yfn.com'
                }, 
                // {
                //     hreflang: 'en-NZ',
                //     href: 'https://nz.yfn.com'
                // }, 
                {
                    hreflang: 'pl',
                    href: 'https://www.yfn.pl'
                }, 
                {
                    hreflang: 'pt',
                    href: 'https://pt.yfn.com'
                }, 
                // {
                //     hreflang: 'ar-SA',
                //     href: 'https://sa.yfn.com'
                // }, 
                // {
                //     hreflang: 'en-AE',
                //     href: 'https://m.yfn.ae'
                // }, 
                {
                    href: 'https://m.yfn.com',
                    hreflang: 'x-default'
                }
            ]
            let link = []
            list.forEach(p => {
                link.push({
                    rel: 'alternate',
                    hreflang: p.hreflang,
                    href: `${p.href}${fullPath}`
                })
            })
            let linkList = list.find((item)=>{
                return this.mRealmName.includes(item.href)
            })
            this.htmlAttrs.lang = linkList?.hreflang||'en'
            return link
        },
        setLink() {
            const countryStore = this.$store.state.national.country
            const country = this.$storage.get('country') || countryStore;
            const pcRealmName = country.pcRealmName || '';
            const mRealmName = country.mRealmName || '';
            const fullPath = this.$route.path;
            this.link = []
            let page = ''
            if(this.$route?.query?.page>1&&(fullPath.indexOf('collection/')!=-1||fullPath.indexOf('/pindex')!=-1)){
                page = `?page=${this.$route?.query?.page}`
                if(this.$route?.query?.page==2){
                    this.link.push({
                        rel: 'prev',
                        href: `${mRealmName}${fullPath}`
                    })
                }else{
                    this.link.push({
                        rel: 'prev',
                        href: `${mRealmName}${fullPath}?page=${Number(this.$route?.query?.page)-1}`
                    })
                }
                
                this.link.push({
                    rel: 'next',
                    href: `${mRealmName}${fullPath}?page=${Number(this.$route?.query?.page)+1}`
                })
            }else if(fullPath.indexOf('collection/')!=-1||fullPath.indexOf('/pindex')!=-1){
                this.link.push({
                    rel: 'next',
                    href: `${mRealmName}${fullPath}?page=2`
                })
            }
            // google 版面
            this.link.push({
                rel: 'canonical',
                href: `${pcRealmName}${fullPath}${page}`
            })
            // Safari分享图标和标题
            this.link.push({
                rel: 'apple-touch-icon',
                href: 'https://cdn2.selleroa.com/yfn-upload/review/1693287424941.png'
            })
            // alternate
            this.link.push(...this.setAltLink())
        },
        // merge
        mergeHead() {
            this.setLang()
            this.setDir()
            this.setMergeMeta()
            this.setLink()
            this.headAttrs.meta = this.meta.merge
            this.headAttrs.link = this.link
            this.headAttrs.htmlAttrs = this.htmlAttrs
        }
    },
}
